<template>
  <div>
    <a-tabs default-active-key="1" tab-position="top">
      <a-tab-pane key="1" tab="基础信息">
        <a-form-model :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 8 }" class="padding-lr">
          <a-form-model-item label="商品名称" prop="name" class="margin-bottom-10">
            <a-input v-model="form.name" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="单位" prop="unit" class="margin-bottom-10">
            <a-input v-model="form.unit" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="商品分类" prop="cateId" class="margin-bottom-10">
            <a-tree-select v-if="cateList.length" v-model="form.cateId" style="width: 100%" :replaceFields="replaceFields"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="cateList" placeholder="请选择商品分类"
              tree-default-expand-all>
            </a-tree-select>
          </a-form-model-item>
          <a-form-model-item label="商品主图" prop="images">
            <a-upload action="" list-type="picture-card" :file-list="fileList" @preview="handlePreview"
              @change="uploadImages">
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
          <a-form-model-item label="商品图册" prop="images">
            <a-upload action="" list-type="picture-card" :file-list="fileList" @preview="handlePreview"
              @change="uploadImages">
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
          <a-form-model-item label="添加视频">
            <a-switch v-model="form.isvideo" checked-children="开启" un-checked-children="关闭" />
          </a-form-model-item>
          <a-form-model-item label="视频类型">
            <a-radio-group v-model="form.videoType" button-style="solid" @change="changeVideo">
              <a-radio-button :value="1">
                本地视频
              </a-radio-button>
              <a-radio-button :value="2">
                视频链接
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label=" ">
            <a-upload v-if="form.videoType === 1" action="" list-type="picture-card" :file-list="fileList"
              @preview="handlePreview" @change="uploadImages">
              <div v-if="fileList.length < 1">
                <a-icon type="video-camera" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-input v-if="form.videoType === 2" placeholder="请输入视频链接"></a-input>
          </a-form-model-item>
          <a-form-model-item label="状态" prop="status" class="margin-bottom-10">
            <a-radio-group v-model="status" button-style="solid">
              <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.value">
                {{ d.label }}
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="2" tab="规格库存">
        <a-form-model :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 5 }">
          <a-form-model-item class="margin-bottom-10" label="商品规格">
            <a-radio-group v-model="form.isSpec" button-style="solid" @change="changeSpec">
              <a-radio-button :value="0">
                单规格
              </a-radio-button>
              <a-radio-button :value="1">
                多规格
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <div v-show="form.isSpec === 0">
            <a-form-model-item class="margin-bottom-10" label="售价" prop="price">
              <a-input v-model="form.price" placeholder="请输入" suffix="￥" />
            </a-form-model-item>
            <a-form-model-item class="margin-bottom-10" label="成本价" prop="cost_price">
              <a-input v-model="form.cost_price" placeholder="请输入" suffix="￥" />
            </a-form-model-item>
            <a-form-model-item class="margin-bottom-10" label="原价" prop="market_price">
              <a-input v-model="form.market_price" placeholder="请输入" suffix="￥" />
            </a-form-model-item>
            <a-form-model-item class="margin-bottom-10" label="库存" prop="stock">
              <a-input v-model="form.stock" placeholder="请输入" />
            </a-form-model-item>
          </div>
          <div v-show="form.isSpec === 1">
            <a-form-model-item label="请选择规格">
              <a-select v-model="curSpec" show-search placeholder="请选择规格" style="width: 200px" @change="handleChangeSpec">
                <a-select-option :value="item.id" v-for="(item, index) in form.specList" :key="indexs">
                  {{ item.label}}
                </a-select-option>
              </a-select>
              <a-button v-if="curSpec" type="primary" @click="isShowSpec = true">确定</a-button>
            </a-form-model-item>
            <!-- start展示选择的规格信息 -->
            <div v-show="isShowSpec">
              <div class="box margin-bottom-sm" v-for="(item, index) in form.specList[0].specs" :key="index">
                <div class="margin-bottom-xs">{{ item.label }}
                  <a-icon type="close-circle" class="cursorhand margin-left-sm" @click="delSpec(index)" />
                </div>
                <div class="flex align-center">
                  <div v-for="(item1, index1) in item.spec_value" :key="index" class="flex align-center">
                    <a-input v-model="item1.label" placeholder="请输入" size="small" style="width: 80%" />
                    <a-button v-if="(index1 + 1) === item.spec_value.length" type="primary" size="small"
                      @click="add(2, index, index1)" class="margin-left-sm margin-right">
                      添加
                    </a-button>
                    <a-icon type="close-circle" class="cursorhand margin-left-sm" @click="del(2, index, index1)"></a-icon>
                  </div>
                </div>
              </div>
            </div>
            <!-- end  展示选择的规格信息 -->
            <a-button class='margin-tb' type="primary" @click="generateSpecs">立即生成</a-button>
            <!-- start 根据所选的规格，生成skuList,展示表格 -->
            <a-table :loading="loading" size="small" rowKey="postId" :columns="skuColumns" :data-source="skuList"
              :pagination="false" :bordered="true">
              <!-- <div slot="key_name" slot-scope="text, record">{{  }}</div> -->
              <div slot="price" slot-scope="text, record">
                <a-input placeholder="请输入"></a-input>
              </div>
              <div slot="market_price" slot-scope="text, record">
                <a-input placeholder="请输入"></a-input>
              </div>
              <div slot="stock" slot-scope="text, record">
                <a-input-number placeholder="请输入"></a-input-number>
              </div>
              <div slot="weight" slot-scope="text, record">
                <a-input placeholder="请输入"></a-input>
              </div>
            </a-table>
            <!-- end   根据所选的规格，生成skuList,展示表格 -->
          </div>
          <!-- <skus></skus> -->
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="3" tab="其他设置">
        <a-form-model-item label="商品详情" prop="content">
          <editor v-model="form.content" />
        </a-form-model-item>
      </a-tab-pane>
    </a-tabs>
    <!-- <div class="bottom-control">
      <a-space>
        <a-button type="primary" :loading="submitLoading" @click="submitForm">
          保存
        </a-button>
        <a-button type="dashed" @click="cancel">
          取消
        </a-button>
      </a-space>
    </div> -->

  </div>
</template>

<script>

import * as article from '@/api/mall/article'
import { list } from '@/api/mall/articlecate'
// import skus from './skuList.vue'
import Editor from '@/components/Editor'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: 'CreatePform',
  props: {

  },
  components: {
    Editor,
    // skus
  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      formTitle: '',
      form: {
        title: '',
        subTitle: '',
        content: '',
        author: '',
        images: [],
        postSort: 0,
        status: 1,
        cateId: undefined,
        isSpec: 0,
        specList: [
          {
            "id": 1,
            "label": "型号",
            "specs": [
              {
                "id": 94,
                "label": "颜色",
                "spec_value": [
                  {
                    "id": 302,
                    "label": "宝蓝",
                    "thumb": null
                  },
                  {
                    "id": 303,
                    "label": "卡其",
                    "thumb": null
                  }
                ]
              },
              {
                "id": 95,
                "label": "尺码",
                "spec_value": [
                  {
                    "id": 304,
                    "label": "80"
                  },
                  {
                    "id": 305,
                    "label": "90"
                  }
                ]
              }
            ]
          }
        ],
        isVideo: 1,
        videoType: 1  // 1本地视频 2视频链接
      },
      isShowSpec: false,
      // 生成的skuList表格数据
      skuColumns: [
        {
          title: '规格',
          dataIndex: 'key_name',
          align: 'center',
          scopedSlots: { customRender: 'key_name' }
        }, {
          title: '价格',
          dataIndex: 'price',
          align: 'center',
          width: 140,
          scopedSlots: { customRender: 'price' }
        }, {
          title: '市场价格',
          dataIndex: 'market_price',
          align: 'center',
          width: 140,
          scopedSlots: { customRender: 'market_price' }
        }, {
          title: '库存',
          dataIndex: 'stock',
          align: 'center',
          width: 140,
          scopedSlots: { customRender: 'price' }
        }, {
          title: '重量',
          dataIndex: 'weight',
          align: 'center',
          width: 140,
          scopedSlots: { customRender: 'weight' }
        }
      ],
      curSpec: undefined,
      skuList: [],
      replaceFields: {
        title: 'label',
        key: 'id',
        value: 'id',
        children: 'children'
      },
      cateList: [],
      statusOptions: [
        {
          label: '上架',
          value: 1
        },
        {
          label: '下架',
          value: 0
        }
      ],
      status: 1,
      // 上传图片
      // open: false,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      rules: {
        title: [{ required: true, message: '文章标题不能为空' }],
        subTitle: [{ required: true, message: '副标题不能为空' }],
        cateId: [{ required: true, message: '请选择文章分类' }],
        content: [{ required: true, message: '请输入文章内容' }],
      },
    }
  },
  filters: {
  },
  created() {
    this.getArticleCate()
  },
  computed: {

  },
  watch: {
  },
  methods: {
    // 过去文章分类
    getArticleCate() {
      this.loading = false
      this.cateList = [{
        "id": 1,
        "pid": 0,
        "label": "热门推荐",
        "image": "",
        "sort": 0,
        "status": 1,
        "children": [
          {
            "id": 20,
            "pid": 1,
            "label": "新闻动态",
            "image": "",
            "sort": 0,
            "status": 1
          }
        ]
      },
      {
        "id": 2,
        "pid": 0,
        "label": "APP问题",
        "image": "",
        "sort": 0,
        "status": 1,
        "children": []
      }]
      return
      list.then(res => {

      }).catch(err => {

      })
    },
    // onClose() {
    //   this.open = false
    // },
    // // 取消按钮
    // cancel() {
    //   this.open = false
    //   this.reset()
    // },
    // 表单重置
    reset() {
      this.form = {
        title: '',
        subTitle: '',
        content: '',
        author: '',
        images: [],
        postSort: 0,
        status: 1,
        cateId: undefined
      }
    },
    changeVideo(e) {
      console.log('切换', e)
      let value = e.target.value
      this.isVideo = value
    },
    changeSpec(e) {
      let value = e.target.value
      this.form.isSpec = value
    },
    /** 切换单规格还是多规格 **/
    handleChangeSpec(e) {console.log('选中规格',e)
      this.curSpec = e
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.formTitle = '添加文章'
      this.reset()
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      const id = row ? row.id : ids
      this.formTitle = '修改文章'
      return
      article.detail({ id: id }).then(response => {
        this.form = response.data
        this.formTitle = '修改文章'
      })
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 上传图片
    uploadImages({ fileList }) {
      console.log('fileList', fileList)
      this.fileList = fileList
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.postId !== undefined) {
            console.log('form', this.form)
            return
            updatePost(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            console.log('form', this.form)
            console.log('上传的封面图', this.fileList)
            this.form.images = this.fileList
            return
            addPost(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /** 生成规格库存 **/
    generateSpecs() {
      let arrs = this.form.specList[0].specs
      console.log('arrs', arrs)
      /**
       * 思路: 以第一项为基础,循环合并之后的每一项再循环的值
       * @param {*} acc 累计的值
       * @param {*} cur 当前遍历项
       * @param {*} index 当前遍历索引
       */
      let result = arrs.reduce((acc, cur, index) => {
        // 从第二项开始合并值
        if (index > 0) {
          let saveArr = []
          acc.spec_value.forEach(item => {
            cur.spec_value.forEach(subItem => {
              saveArr.push({
                sku_id: 0,
                key: `${item.id}-${subItem.id}`,
                key_name: `${item.label}-${subItem.label}`,
                stock: 0,
                weight: 0,
                price: 0,
                market_price: 0
              })
            })
          })
          acc = saveArr
        }
        return acc
      }, arrs[0]) // 把数组的第一项传入作为初始值
      console.log(result) //["红,大", "红,中", "红,小", "黄,大", "黄,中", "黄,小", "蓝,大", "蓝,中", "蓝,小"]
      this.skuList = result
    }
  }
}
</script>
<style lang="less">
.ant-upload-list-picture-card-container,
.ant-upload-select-picture-card,
.ant-upload-list-item {
  width: 80px !important;
  height: 80px !important;
}
.cursorhand {
  cursor: pointer;
}
</style>